<template>
  <div id="document-aip" class="vld-parent">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="row">
      <label id="radios-label">ON SUCCESS</label>
      <md-radio id="send-to-folder-radio" v-model="radio" value="send-to-folder">
        Send to Folder
      </md-radio>
      <md-radio id="send-to-workflow-radio" v-model="radio" value="send-to-workflow">
        Send to Workflow
      </md-radio>
    </div>

    <div v-show="radio === 'send-to-folder'" class="row flex-row">
      <div class="half-width">
        <VasionInput
          id="success-folder"
          v-model="successFolderName"
          class="browse-input"
          title="SUCCESS FOLDER"
          inputType="top-white"
          name="success-folder"
          placeholder="Select Folder..."
          :isDisabled="true"
          :isInErrorState="errorSuccessFolder"
        />
        <VasionButton
          id="first-browse-btn"
          class="browse-folders-btn"
          :classProp="'primary'"
          @vasionButtonClicked="toggleBrowseFolderDialog('success')"
        >
          Browse
        </VasionButton>
      </div>
      <div class="half-width">
        <VasionInput
          id="failure-folder"
          v-model="failureFolderName"
          class="browse-input"
          title="FAILURE FOLDER"
          inputType="top-white"
          name="failure-folder"
          placeholder="Select Folder..."
          :isDisabled="true"
          :isInErrorState="errorFailureFolder"
        />
        <VasionButton
          class="browse-folders-btn"
          :classProp="'primary'"
          @vasionButtonClicked="toggleBrowseFolderDialog('failure')"
        >
          Browse
        </VasionButton>
      </div>
    </div>
    <div v-show="radio === 'send-to-workflow'" class="row">
      <VasionDropList
        id="workflow-drop-list"
        v-slot="slotItem"
        v-model="selectedWorkflow"
        :dataArray="workflowList"
        displayName="sName"
        title="Workflow"
        width="100%"
        :isInErrorState="errorWorkflow"
        @input="setSelectedWorkflow($event)"
      >
        {{ slotItem.item.sName }}
      </VasionDropList>
    </div>

    <div id="add-image-processes-row" class="row">
      <p v-if="updatedAIPList && updatedAIPList.length === 0" id="no-available-aips" class="vasion-error-text">
        No Available AIPs To Add
      </p>
      <VasionDropList
        id="aip-drop-list"
        v-slot="slotItem"
        :dataArray="updatedAIPList"
        displayName=""
        title="ADD IMAGE PROCESSES"
        :placeholder="aipDroplistPlaceholder"
        width="100%"
        :isInErrorState="errorAIPs"
        @input="appendAIP($event)"
      >
        {{ slotItem.item.procName }}
      </VasionDropList>

      <div id="aip-table-div">
        <table v-show="selectedAIPs && selectedAIPs.length > 0" id="aip-table">
          <tr v-for="item in selectedAIPs" :key="item">
            <td class="td-name">
              {{ item.name }}
            </td>
            <td class="td-icon">
              <VasionRemoveCircleOutlineIcon @click="removeItem(item)" />
            </td>
          </tr>
        </table>
      </div>
    </div>

    <VasionGeneralModal
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'OK'"
      :confirmButtonDisabled="!folderSelected"
      :modalType="'slot'"
      :sync="showBrowseFoldersDialog"
      @confirmButtonClick="folderDialogOK"
      @noButtonClick="handleCancel"
    >
      <div class="folder-dialog-browse">
        <VasionFolders
          @itemSelected="handleItemSelected"
        />
      </div>
    </VasionGeneralModal>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'DocumentAIP',
  components: {
    Loading,
  },
  data: function () {
    return {
      aipDroplistPlaceholder: 'Search Image Processes...',
      errorAIPs: false,
      errorFailureFolder: false,
      errorSuccessFolder: false,
      errorWorkflow: false,
      failureFolderID: 0,
      failureFolderName: '',
      folderSelected: false,
      folderSelectionType: '',
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      radio: 'send-to-folder',
      showBrowseFoldersDialog: false,
      selectedAIPs: [],
      selectedWorkflow: {},
      successFolderID: 0,
      successFolderName: '',
      tempFolderID: 0,
      tempFolderName: '',
      updatedAIPList: [],
      workflowList: [],
    }
  },
  computed: {
    aipList() { return this.$store.state.capture.aipData.Values },
    selectedAIPIDs() {
      const IDs = this.selectedAIPs.map(item => {
        return item.id
      })
      return IDs
    },
  },
  async created() {
    this.isLoading = true

    const promises = await Promise.all([
      this.$store.dispatch('workflow/getWorkflowMenuItems'),
      this.$store.dispatch('capture/getAllAipProcs'),
    ])
    // eslint-disable-next-line
    this.workflowList = promises[0]
    this.workflowList.shift()

    this.updateAIPList()

    this.isLoading = false
  },
  methods: {
    appendAIP(e) {
      this.selectedAIPs.push({
        name: e.procName,
        id: e.aiAIPID,
      })
      this.updateAIPList()
    },
    folderDialogOK() {
      if (this.folderSelected) {
        this.showBrowseFoldersDialog = false
        switch (this.folderSelectionType) {
          case 'success':
            this.successFolderID = this.tempFolderID
            this.successFolderName = this.tempFolderName
            break
          case 'failure':
            this.failureFolderID = this.tempFolderID
            this.failureFolderName = this.tempFolderName
            break
          default:
            break
        }
      }
    },
    handleCancel() {
      this.folderSelected = false
      this.showBrowseFoldersDialog = false
    },
    handleItemSelected(item) {
      this.folderSelected = true
      this.tempFolderID = item.value
      this.tempFolderName = item.name
    },
    removeItem(item) {
      this.selectedAIPs.splice(this.selectedAIPs.indexOf(item), 1)
      this.updateAIPList()
    },
    setSelectedWorkflow(e) { this.selectedWorkflow = e },
    toggleBrowseFolderDialog(selectionType) {
      switch (selectionType) {
        case 'success':
          this.folderSelectionType = 'success'
          break
        case 'failure':
          this.folderSelectionType = 'failure'
          break
        default:
          this.folderSelectionType = ''
          console.warn(`Browsing unknown folder selection.\nReceived: "${selectionType}"`)
          break
      }

      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog
    },
    updateAIPList() {
      this.updatedAIPList = []
      this.aipList.forEach(originalItem => {
        if (!this.selectedAIPIDs.includes(originalItem.aiAIPID)) {
          this.updatedAIPList.push(originalItem)
        }
      })

      this.aipDroplistPlaceholder = this.updatedAIPList.length === 0 ? 'No Available AIPs' : 'Search Image Processes...'
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #document-aip {
    max-height: 636px;
    max-width: 928px;
    height: 80vh;
    width: 90vw;
    overflow: hidden;
  }

  .row {
    margin: 0;
  }
  .flex-row {
    display: flex;
  }

  #radios-label {
    display: block;
    margin-bottom: -5px;
    margin-top: 25px;
    color: $grey-400;
    font-size: 12px;
    font-weight: 500;
  }

  .half-width {
    width: 50%;
    display: flex;
    flex-direction: row;
  }

  .browse-input {
    width: 100%;
  }
  .browse-folders-btn {
    padding-top: 17px;
  }
  #first-browse-btn {
    margin-right: 15px;
  }

  #add-image-processes-row {
    margin-top: 25px;
  }

  #aip-table-div {
    margin-top: 15px;
    max-height: 350px;
    overflow: auto;
  }
  #aip-table {
    width: 100%;

    tr {
      height: 48px;
      font-size: 14px;
    }
    tr:nth-child(odd) {
      background: $grey-50;
    }

    td {
      padding: 0 15px;
    }
    .td-name {
      width: 100%;
    }
    .td-icon {
      padding-top: 5px;
    }
    .td-icon:hover {
      cursor: pointer;
      fill: $orange-300;
      transition: 0.3s;
    }
  }
</style>
