<template>
  <VasionFolderSelect
    title="Copy Documents"
    okButtonText="Copy"
    customCheckBoxText="Copy Field Data"
    :customCheckBoxChecked="copyAttributeData"
    @cancelButtonClick="cancelClick"
    @okButtonClick="copyClick"
    @toggleCustomCheckbox="toggleCheckbox"
  />
</template>

<script>
import VasionFolderSelect from '@/components/shared/VasionFolderSelect.vue';

export default {
  name: 'DocumentCopy',
  components: {
    VasionFolderSelect,
  },
  props: {
    documentIDArray: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      copyAttributeData: true,
    }
  },
  methods: {
    cancelClick() {
      this.$emit('close')
    },
    async copyClick(payload) {
      const copyPayload = {
        copyDocumentIDs: this.documentIDArray,
        destinationFolderID: payload.SelectedFolderID,
        copyAttributeFields: this.copyAttributeData,
      }

      const copyResult = await this.$store.dispatch('document/copyDocuments', copyPayload)
      this.$emit('copy-complete', copyResult)
    },
    toggleCheckbox() {
      this.copyAttributeData = !this.copyAttributeData
    },
  },
}
</script>
